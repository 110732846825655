'use client';
import React from 'react';
import { LinkClickable } from '@mentimeter/next-navigation';
import { Device } from '@mentimeter/ragnar-device';
import {
  MentimeterLogoType,
  MentimeterSymbol,
} from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';

const HEADER_HEIGHT = '7rem';

export const VanillaHeader = () => {
  return (
    <Box
      bg="bg"
      extend={() => ({
        '@media print': {
          display: 'none',
        },
      })}
    >
      <Box
        width="100%"
        height={HEADER_HEIGHT}
        position="relative"
        color="onPrimary"
      >
        <Box
          position="fixed"
          width="100%"
          py={2}
          px={2}
          color="text"
          alignItems="center"
          flexDirection="row"
          zIndex={4}
          extend={({ theme }) => ({
            borderBottom: `2px solid ${theme.colors.borderWeak}`,
          })}
        >
          <Box
            flexDirection={['row', 'row', 'column']}
            alignItems={['center', 'center', 'start']}
            px={3}
          >
            <Device.Match greaterThan={2}>
              <LinkClickable href="/">
                <MentimeterLogoType height={23} width={189} />
              </LinkClickable>
            </Device.Match>

            <Device.Match lessThan={3}>
              <LinkClickable
                href="/"
                width="27px"
                height="23px"
                color="currentColor"
                mr={2}
              >
                <MentimeterSymbol />
              </LinkClickable>
            </Device.Match>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
